import React from 'react';
import Styles from './questionScreen.module.css';
import gsap from 'gsap';
import { TimelineMax } from 'gsap';
import SectionIcon from '../sectionIcon';
import UFSLogo from '../../assets/UFS-logo.svg';
import SpinnerLottie from '../../assets/spinner.json';
import Lottie from 'react-lottie';
import AxiosConfig from '../../utils/axiosConfig';
import { ENUMS, apiErrorResponse } from '../../utils';

class QuestionScreen extends React.Component {
  constructor(props) {
    super(props);

    this.firstName = React.createRef();
    this.lastName = React.createRef();
    this.email = React.createRef();
    this.phone = React.createRef();
    this.location = React.createRef();
    this.isBusinessFranchise = React.createRef();
    this.businessFranchiseName = React.createRef();
    this.businessType = React.createRef();
    this.businessPurchaseTime = React.createRef();
    this.availableCash = React.createRef();
    this.retirementFunds = React.createRef();
    this.giftsValue = React.createRef();
    this.stocksValue = React.createRef();
    this.houseValue = React.createRef();
    this.mortgageValue = React.createRef();
    this.estimatedCredit = React.createRef();
    this.employmentStatus = React.createRef();
    this.estimatedAnnualIncome = React.createRef();
    this.continueBtn = React.createRef();

    this.state = {
      sectionIndex: 1,
      sectionStep: 1,
      disableNextStep: true,
      headingText: 'Let’s start with introductions!',
      questionText: 'What’s your name?',
      error: '',
      sectionComplete: false,
      showSpinner: false,
      selectedFranchiseOption: '',
      homeOwnerOption: '',
      mortgageOption: '',
      employmentOption: '',
      militaryOption: '',
      showError: false,
      unsecuredLoanEstimate: ''
    }
  }

  nameInputChange() {
    this.setState({disableNextStep: (this.firstName.current.value.length && this.lastName.current.value.length) ? (false) : (true)})
  }

  optionChange(optionValue, optionName) {
    if (optionName === 'franchiseName') {
      this.setState({selectedFranchiseOption: optionValue})
    } else if (optionName === 'houseValue') {
      this.setState({homeOwnerOption: optionValue})
    } else if (optionName === 'mortgageValue') {
      this.setState({mortgageOption: optionValue})
    } else if (optionName === 'employmentValue') {
      this.setState({employmentOption: optionValue})
    }

    document.querySelector('.' + optionName + 'Input').style.display = (optionValue === 'Yes') ? ('block') : ('none');

    gsap.to('.' + optionName + 'Input', {
      opacity: (optionValue === 'Yes') ? (1) : (0),
      duration: 0.5
    })

    if (optionValue === 'No') {
      this.setState({
        error: '',
        disableNextStep: false
      })
    }
  }

  inputChange(e, input, currency = false) {
    this.setState({
      disableNextStep: (input.current.value.length) ? (false) : (true),
      error: ''
    })

    if (input.current.value.length && e.keyCode === 13 && !this.state.sectionComplete) {
      this.nextStep();
    }

    if (input.current.value.length && e.keyCode === 9 && !this.state.sectionComplete) {
      document.getElementById('nextButton').focus();
    }
    
    if (currency) {
      var userInput = input.current.value.replace(/[$|,]/g, '')

      if (!isNaN(parseInt(userInput))) {
        input.current.value = parseInt(userInput).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumSignificantDigits: 21 }).replace(/\$/g, '')
      } else {
        this.setState({
          disableNextStep: (!isNaN(parseInt(userInput))) ? (false) : (true),
          error: (!isNaN(parseInt(userInput))) ? ('') : ('Please enter a valid value')
        })
      }
    }
  }

  isValidPhoneNumber(e = null) {
    if (e === null || (e !== null && e.keyCode !== 8)) {
      var phoneNumber = this.phone.current.value.replace(/\D/g,'');

      this.setState({showError: false})

      if (/^\d+$/.test(phoneNumber) && phoneNumber.length > 0) {
        if (phoneNumber.length <= 2) {
          this.phone.current.value = phoneNumber;
        } else if (phoneNumber.length <= 10 && phoneNumber.length > 2) {
          this.phone.current.value = ('(' + phoneNumber.substring(0,3) + ') ' + phoneNumber.substring(3,6) + '-' + phoneNumber.substring(6,10));
        } else if (phoneNumber.length > 10) {
          this.phone.current.value = (phoneNumber.substring(0,1) + ' (' + phoneNumber.substring(1,4) + ') ' + phoneNumber.substring(4,7) + '-' + phoneNumber.substring(7,11));
        }

        if (phoneNumber.length && phoneNumber.length >= 10) {
          this.setState({
            disableNextStep: false,
            error: '',
            showError: false
          })
        } else {
          this.setState({
            disableNextStep: true,
            error: 'Please enter a valid Phone Number'
          })
        }
      } else {
        this.setState({
          disableNextStep: true,
          error: 'Please enter a valid Phone Number'
        })
      }
    }
  }

  switchStep(sectionIndex, sectionStep, nextStepCheck = true) {
    this.setState({
      sectionStep: sectionStep,
      sectionIndex: sectionIndex,
    })

    var textAnimation = new TimelineMax();

    textAnimation.to('.rightPaneContent', {
      x: (nextStepCheck) ? ('-100vw') : ('100vw'),
      duration: 0.3,
      onComplete: async() => {
        var questionText = '';
        var headingText = '';
        var focusElement = '';
        var disableNextStep = true;

        if (sectionIndex === 1) {
          if (sectionStep === 1) {
            headingText = 'Let’s start with introductions!';
            questionText = 'What’s your name?';
            focusElement = this.firstName;
            disableNextStep = (this.firstName.current.value.length && this.lastName.current.value.length) ? (false) : (true);
          } else if (sectionStep === 2) {
            headingText = 'Thanks for using BuyerPrequal, ' + this.firstName.current.value + '!';
            questionText = 'What’s the best email address and phone number for you?';
            focusElement = this.email;
          } else if (sectionStep === 3) {
            questionText = 'What state do you live in?';
            focusElement = this.location;
            disableNextStep = (this.location.current.value !== '') ? (false) : (true);
          }
        } else if (sectionIndex === 2) {
          if (this.state.sectionStep === 1) {
            questionText = 'Is this business a franchise?';
          } else if (sectionStep === 2) {
            questionText = (this.state.selectedFranchiseOption === 'Yes') ? ('In what industry is your franchise?') : ('What kind of business are you looking to purchase?');
            focusElement = this.businessType;
            disableNextStep = (this.businessType.current.value !== '') ? (false) : (true);
          } else if (sectionStep === 3) {
            questionText = 'When are you looking to purchase your business?';
            focusElement = this.businessPurchaseTime;
            disableNextStep = (this.businessPurchaseTime.current.value !== '') ? (false) : (true);
          }
        } else if (sectionIndex === 3) {
          if (this.state.sectionStep === 1) {
            questionText = 'How much cash do you have available, including checking and savings accounts?';
            focusElement = this.availableCash;
            disableNextStep = (this.availableCash.current.value !== '') ? (false) : (true);
          } else if (sectionStep === 2) {
            questionText = 'Do you have any retirement savings, such as a 401k or IRA? What is the combined value of your retirement accounts?';
            focusElement = this.retirementFunds;
            disableNextStep = (this.retirementFunds.current.value !== '') ? (false) : (true);
          } else if (sectionStep === 3) {
            questionText = 'Do you expect to get any cash gifts from friends or family to help with the purchase? If so, how much? (Put 0 if no gifts are expected)';
            focusElement = this.giftsValue;
            disableNextStep = (this.giftsValue.current.value !== '') ? (false) : (true);
          } else if (sectionStep === 4) {
            questionText = 'What about stocks or bonds? What’s the approximate value of any tradable securities you own? (Put 0 if you do not own any securities)';
            focusElement = this.stocksValue;
            disableNextStep = (this.stocksValue.current.value !== '') ? (false) : (true);
          } else if (sectionStep === 5) {
            questionText = 'Do you own a home?';
          } else if (sectionStep === 6) {
            if (this.state.homeOwnerOption === 'No') {
              this.nextStep()

              return;
            } else {
              questionText = 'Do you have a mortgage on your primary residence?';
            }
          }
        } else if (sectionIndex === 4) {
          if (this.state.sectionStep === 1) {
            questionText = 'What\'s your estimated credit score?';
            focusElement = this.estimatedCredit;
            disableNextStep = (this.estimatedCredit.current.value !== '') ? (false) : (true);
          } else if (sectionStep === 2) {
            questionText = 'Are you currently employed?';
          } else if (sectionStep === 3) {
            questionText = 'What’s your estimated annual income?';
            focusElement = this.estimatedAnnualIncome;
            disableNextStep = (this.estimatedAnnualIncome.current.value !== '') ? (false) : (true);
          } else if (sectionStep === 4) {
            questionText = 'And finally, are you a military veteran or on active duty? ';
          }
        }

        this.setState({
          questionText: questionText,
          headingText: headingText,
          disableNextStep: disableNextStep
        })

        if (focusElement) {
          focusElement.current.focus();
        }

        document.querySelector('.sectionCompleteContainer').style.display = 'none';
      }
    }).to('.rightPaneContent', {
      opacity: 0,
      x: (nextStepCheck) ? ('100vw') : ('-100vw'),
      duration: 0
    }).to('.rightPaneContent', {
      opacity: 1,
      duration: 0
    }).to('.rightPaneContent', {
      x: (nextStepCheck) ? (0) : (0),
      duration: 0.3
    })
  }

  previousStep() {
    var sectionIndex = '';
    var sectionStep = '';

    this.setState({
      error: '',
      showError: false,
      disableNextStep: false
    })

    if (this.state.sectionComplete) {
      sectionIndex = this.state.sectionIndex;
      sectionStep = this.state.sectionStep;

      this.setState({sectionComplete: false})
    } else {
      if (this.state.sectionIndex === 2 && this.state.sectionStep === 1) {
        sectionIndex = 1;
        sectionStep = 3;
      } else if (this.state.sectionIndex === 3 && this.state.sectionStep === 1) {
        sectionIndex = 2;
        sectionStep = 3;
      } else if (this.state.sectionIndex === 4 && this.state.sectionStep === 1) {
        sectionIndex = 3;
        sectionStep = (this.state.homeOwnerOption === 'Yes') ? (6) : (5);
      } else {
        sectionIndex = this.state.sectionIndex;
        sectionStep = this.state.sectionStep - 1;
      }
    }
    
    this.switchStep(sectionIndex, sectionStep, false);
  }

  skipStep() {
    if (this.state.sectionIndex === 2 && this.state.sectionStep === 2) {
      this.businessType.current.value = '';
    }

    this.nextStep();
  }

  async nextStep() {
    if (this.state.sectionIndex === 1 && this.state.sectionStep === 2) {
      if (this.email.current.value.length < 1 || this.email.current.validity.patternMismatch) {
        await this.setState({
          error: 'Please enter a valid Email Address',
          disableNextStep: true,
          showError: true
        })
      } else {
        await this.isValidPhoneNumber();
      }
    } else if (this.state.sectionIndex === 2 && this.state.sectionStep === 1 && this.state.selectedFranchiseOption !== '') {
      await this.setState({disableNextStep: false})
    } else if (this.state.sectionIndex === 3 && this.state.sectionStep === 5) {
      if (this.state.homeOwnerOption === 'Yes') {
        await this.setState({disableNextStep: (this.houseValue.current.value.length) ? (false) : (true)})
      } else if (this.state.homeOwnerOption === 'No') {
        await this.setState({disableNextStep: false})
      }
    } else if (this.state.sectionIndex === 3 && this.state.sectionStep === 6) {
      if (this.state.mortgageOption === 'Yes') {
        await this.setState({disableNextStep: (this.mortgageValue.current.value.length) ? (false) : (true)})
      } else if (this.state.mortgageOption === 'No') {
        await this.setState({disableNextStep: false})
      }
    } else if (this.state.sectionIndex === 4 && this.state.sectionStep === 2) {
      if (this.state.employmentOption === 'Yes') {
        await this.setState({disableNextStep: (this.employmentStatus.current.value.length) ? (false) : (true)})
      } else if (this.state.employmentOption === 'No') {
        await this.setState({disableNextStep: false})
      }
    }

    if (this.state.disableNextStep && !(this.state.sectionIndex === 2 && this.state.sectionStep === 2)) {
      this.setState({
        error: (this.state.error) ? (this.state.error) : ('Sorry, this is a BuyerPrequal required field - please try again!'),
        showError: true
      })
    } else {
      this.setState({
        error: '',
        showError: false,
        disableNextStep: true
      })

      var sectionIndex = '';
      var sectionStep = '';

      if (this.state.sectionIndex === 1 && this.state.sectionStep === 2) {
        this.submitPreQualificationForm(0);
      } else if (this.state.sectionIndex === 4 && this.state.sectionStep === 1) {
        this.submitPreQualificationForm(3);
      } else if (this.state.sectionIndex === 4 && this.state.sectionStep === 2) {
        this.submitPreQualificationForm(4);
      } else if (this.state.sectionIndex === 4 && this.state.sectionStep === 3) {
        this.submitPreQualificationForm(5);
      }

      if (this.state.sectionIndex === 1 && this.state.sectionStep === 3) {
        this.submitPreQualificationForm(1);

        this.setState({
          sectionCompleteHeading: 'Thank you for telling us about yourself, ' + this.firstName.current.value + '!',
          sectionCompleteText: 'Now tell us a bit about the business you want to buy'
        })

        this.sectionComplete();
      } else if (this.state.sectionIndex === 2 && this.state.sectionStep === 3) {
        this.submitPreQualificationForm(2);

        this.setState({
          sectionCompleteHeading: 'Awesome, ' + this.firstName.current.value + '!',
          sectionCompleteText: 'Now let’s learn a bit about your financial situation so we can find out what you can afford to buy'
        })

        this.sectionComplete();
      } else if (this.state.sectionIndex === 3 && this.state.sectionStep === ((this.state.homeOwnerOption === 'Yes') ? (6) : (5))) {
        this.setState({
          sectionCompleteHeading: 'Thanks for telling us about your finances',
          sectionCompleteText: 'You’re almost done! In order to get you an accurate BuyerPrequal, we’ll just need a bit of information about your credit history'
        })

        this.sectionComplete();
      } else if (this.state.sectionIndex === 4 && this.state.sectionStep === 4) {
        this.submitPreQualificationForm(6);

        this.setState({
          sectionCompleteHeading: 'Thank you for sharing your complete background and history with us!',
          sectionCompleteText: 'Please wait while we crunch the numbers and calculate how much you are prequalified for.',
          showSpinner: true
        })

        this.sectionComplete();
        this.calculatePreQualification();
      } else {
        sectionIndex = this.state.sectionIndex;
        sectionStep = this.state.sectionStep + 1;

        this.switchStep(sectionIndex, sectionStep);
      }
    }
  }

  async sectionComplete() {
    document.querySelector('.sectionCompleteContainer').style.left = '100vw';
    document.querySelector('.sectionCompleteContainer').style.display = 'flex';

    gsap.to('.sectionCompleteContainer', {
      left: '0',
      duration: 0.4
    })

    if (this.continueBtn.current) {
      this.continueBtn.current.focus();
    }

    this.setState({sectionComplete: true})
  }

  async nextSection() {
    gsap.to('.sectionCompleteContainer', {
      left: '-100vw',
      duration: 0.5
    })

    await this.setState({
      sectionIndex: this.state.sectionIndex + 1,
      sectionStep: 1,
      sectionComplete: false,
      disableNextStep: true,
      error: ''
    })

    this.switchStep(this.state.sectionIndex, this.state.sectionStep)
  }

  async calculatePreQualification() {
    var houseValue = (this.state.homeOwnerOption === 'Yes') ? (parseInt(this.houseValue.current.value.replace(/,/g, ''))) : (0);
    var mortgageValue = (this.state.mortgageOption === 'Yes') ? (parseInt(this.mortgageValue.current.value.replace(/,/g, ''))) : (0);
    var netHomeEquityValue =  houseValue - mortgageValue ;
    var netAvailableAssets = (parseInt(this.availableCash.current.value.replace(/,/g, '')) * 0.9) + (parseInt(this.retirementFunds.current.value.replace(/,/g, '')) * 0.5) + (parseInt(this.giftsValue.current.value.replace(/,/g, ''))) + (parseInt(this.stocksValue.current.value.replace(/,/g, ''))) + (netHomeEquityValue * 0.5);
    var SBALoanEstimate1 = netAvailableAssets / 0.1;
    var SBALoanEstimate2 = netAvailableAssets / 0.2;
    var unsecuredLoanEstimate = 0;

    if (this.estimatedCredit.current.value !== 'Not so good (<599)' && this.estimatedCredit.current.value !== 'Okay (600 - 679)' && this.estimatedAnnualIncome.current.value > 25000) {
      unsecuredLoanEstimate = 1.5 * this.estimatedAnnualIncome.current.value;
    }

    await this.setState({unsecuredLoanEstimate: unsecuredLoanEstimate})

    await this.submitPreQualificationForm(7);

    await setTimeout((e) => {
      this.props.history.push({
        pathname: '/letter',
        state: { 
          SBALoanEstimate1: SBALoanEstimate1,
          SBALoanEstimate2: SBALoanEstimate2,
          unsecuredLoanEstimate: unsecuredLoanEstimate,
          firstName: this.firstName.current.value,
          lastName: this.lastName.current.value,
          email: this.email.current.value,
          phone: this.phone.current.value,
          location: this.location.current.value,
          isBusinessFranchise: (this.state.selectedFranchiseOption === 'Yes') ? ('Franchise') : ('Business'),
          businessType: (this.businessType.current.value) ? (this.businessType.current.value) : ('N/A'),
          businessPurchaseTime: this.businessPurchaseTime.current.value,
          availableCash: this.availableCash.current.value,
          retirementFunds: this.retirementFunds.current.value,
          giftsValue: this.giftsValue.current.value,
          stocksValue: this.stocksValue.current.value,
          houseValue: (this.houseValue.current.value) ? ('$' + this.houseValue.current.value) : ('N/A'),
          mortgageValue: (this.mortgageValue.current.value) ? ('$' + this.mortgageValue.current.value) : ('N/A'),
          estimatedCredit: this.estimatedCredit.current.value,
          employmentStatus: (this.state.employmentOption === 'Yes') ? (this.employmentStatus.current.value) : ('None'),
          estimatedAnnualIncome: this.estimatedAnnualIncome.current.options[this.estimatedAnnualIncome.current.selectedIndex].text,
          militaryAffiliation: this.state.militaryOption
        }
      })
    }, 5000);
  }

  submitPreQualificationForm(formIndex) {
    var formID = '';
    var formData = [{
      name: 'email',
      value: this.email.current.value
    }];

    if (formIndex === 0) {
      formID = '81e6b459-6407-4365-941c-f73a152779de';

      formData.push({
        name: 'firstname',
        value: this.firstName.current.value
      });

      formData.push({
        name: 'lastname',
        value: this.lastName.current.value
      })

      formData.push({
        name: 'phone',
        value: this.phone.current.value
      })

      formData.push({
        name: 'lead_source',
        value: 'UFS Buyer Prequal'
      })
    } else if (formIndex === 1) {
      formID = '4bbffdc8-64e8-4b0f-951a-0c478d886b81';

      formData.push({
        name: 'state',
        value: this.location.current.value
      });
    } else if (formIndex === 2) {
      formID = '08745b0d-cd92-4166-b2fe-44233e442f34';

      formData.push({
        name: 'timeframe_to_business_purchase',
        value: this.businessPurchaseTime.current.value
      });
    } else if (formIndex === 3) {
      formID = 'd6bb5360-eb86-48d9-9a24-188a3872bd13';

      formData.push({
        name: 'credit_rating',
        value: this.estimatedCredit.current.value
      });
    } else if (formIndex === 4) {
      formID = '8da2dd8a-0be5-452b-a2ef-6ea6e2dcef1c';

      formData.push({
        name: 'currently_employed',
        value: this.state.employmentOption
      });

      formData.push({
        name: 'employed_status',
        value: (this.state.employmentOption === 'No') ? ('N/A') : (this.employmentStatus.current.value)
      });
    } else if (formIndex === 5) {
      formID = '0104b3f9-e99d-484f-949e-21189aafd574';

      formData.push({
        name: 'annual_income',
        value: this.estimatedAnnualIncome.current.options[this.estimatedAnnualIncome.current.selectedIndex].text
      });
    } else if (formIndex === 6) {
      formID = 'b859a32f-445a-4037-9cd3-ae74e5c2ce45';

      formData.push({
        name: 'military',
        value: this.state.militaryOption
      });
    } else if (formIndex === 7) {
      formID = '44974f04-b029-4102-8490-3d01597c21e0';

      formData.push({
        name: 'buyer_prequal_amount',
        value: this.state.unsecuredLoanEstimate
      });
    }

    AxiosConfig.post('/' + ENUMS.HUBSPOT_PORTAL_ID + '/' + formID, {
      'fields': formData
    }).then(response => {
      //console.log(response)
    }).catch(error => {
      apiErrorResponse(error);
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const defaultLottieOptions = {
      loop: true,
      animationData: SpinnerLottie
    }

    return (
      <div className={Styles.mainContainer}>
        <div className={Styles.leftPane}>
          <div className={Styles.sectionsContainer}>
            <img className={Styles.logo} src={UFSLogo} alt="logo" />
            <div className={(this.state.sectionIndex === 1) ? (Styles.activeSection) : (Styles.inactiveSection)}>
              <SectionIcon sectionIndex={1} active={(this.state.sectionIndex === 1) ? (true) : (false)} height="20px" />
              <h5>General Info</h5>
            </div>
            <div className={(this.state.sectionIndex === 2) ? (Styles.activeSection) : (Styles.inactiveSection)}>
              <SectionIcon sectionIndex={2} active={(this.state.sectionIndex === 2) ? (true) : (false)} height="20px" />
              <h5>Deal Details</h5>
            </div>
            <div className={(this.state.sectionIndex === 3) ? (Styles.activeSection) : (Styles.inactiveSection)}>
              <SectionIcon sectionIndex={3} active={(this.state.sectionIndex === 3) ? (true) : (false)} height="20px" />
              <h5>Financial Background</h5>
            </div>
            <div className={(this.state.sectionIndex === 4) ? (Styles.activeSection) : (Styles.inactiveSection)}>
              <SectionIcon sectionIndex={4} active={(this.state.sectionIndex === 4) ? (true) : (false)} height="20px" />
              <h5>Credit History</h5>
            </div>  
          </div>
        </div>
        <div className={Styles.rightPane}>
          <SectionIcon sectionIndex={this.state.sectionIndex} active={false} height="60px" opacity="0.3" />
          <h3 className={`rightPaneContent ${Styles.headingText}`}>{this.state.headingText}</h3>
          <h4 className={`rightPaneContent ${Styles.questionText}`}>{this.state.questionText}</h4>
          <div className={`rightPaneContent ${(this.state.sectionIndex === 1 && this.state.sectionStep === 1) ? (Styles.shown) : (Styles.hidden)} ${Styles.firstInputContainer}`}>
            <p>First Name</p>
            <input autoFocus ref={this.firstName} className={Styles.inputContainer} onKeyUp={(e) => {this.nameInputChange()}} type="text" />
          </div>
          <div className={`rightPaneContent ${(this.state.sectionIndex === 1 && this.state.sectionStep === 1) ? (Styles.shown) : (Styles.hidden)} ${Styles.secondInputContainer}`}>
            <p>Last Name</p>
            <input ref={this.lastName} className={Styles.inputContainer} onKeyUp={(e) => {this.nameInputChange()}} type="text" />
          </div>
          <div className={`rightPaneContent ${(this.state.sectionIndex === 1 && this.state.sectionStep === 2) ? (Styles.shown) : (Styles.hidden)} ${Styles.firstInputContainer}`}>
            <p>Your Email</p>
            <input ref={this.email} className={Styles.inputContainer} onKeyUp={(e) => {this.inputChange(e, this.email)}} type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
          </div>
          <div className={`rightPaneContent ${(this.state.sectionIndex === 1 && this.state.sectionStep === 2) ? (Styles.shown) : (Styles.hidden)} ${Styles.secondInputContainer}`}>
            <p>Your Phone Number</p>
            <input ref={this.phone} className={Styles.inputContainer} type="tel" onKeyUp={(e) => {this.isValidPhoneNumber(e)}} />
          </div>
          <select ref={this.location} className={`rightPaneContent ${(this.state.sectionIndex === 1 && this.state.sectionStep === 3) ? (Styles.shown) : (Styles.hidden)} ${Styles.inputContainer} ${Styles.dropdownContainer}`} onChange={(e) => {this.inputChange(e, this.location)}}>
            <option value="" selected="selected">Select a State</option>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="District Of Columbia">District Of Columbia</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </select>
          <div className={`mt-4 rightPaneContent ${(this.state.sectionIndex === 2 && this.state.sectionStep === 1) ? (Styles.shown) : (Styles.hidden)} ${Styles.franchiseInputContainer}`}>
            <div>
              <button className={`${Styles.optionBtn} ${(this.state.selectedFranchiseOption === 'Yes') ? (Styles.selectedOption) : (null)}`} onClick={() => {this.optionChange('Yes', 'franchiseName')}}>Yes</button>
              <button className={`${Styles.optionBtn} ${(this.state.selectedFranchiseOption === 'No') ? (Styles.selectedOption) : (null)}`} onClick={() => {this.optionChange('No', 'franchiseName')}}>No</button>
            </div>
            <div className={`w-100 franchiseNameInput ${Styles.franchiseNameInputContainer}`}>
              <h4 className="mt-5">What’s the name of the franchise? (Optional)</h4>
              <input ref={this.businessFranchiseName} className={`m-0 mt-2 ${Styles.inputContainer}`} onKeyUp={(e) => {this.inputChange(e, this.businessFranchiseName)}} type="text" />
            </div>
          </div>
          <select ref={this.businessType} className={`rightPaneContent ${(this.state.sectionIndex === 2 && this.state.sectionStep === 2) ? (Styles.shown) : (Styles.hidden)} ${Styles.inputContainer} ${Styles.dropdownContainer}`} onChange={(e) => {this.inputChange(e, this.businessType)}}>
            <option value="" selected="selected">Select a business type</option>
            <option value="Agriculture, Forestry, Mining">Agriculture, Forestry, Mining</option>
            <option value="Data Infrastructure, Telecom">Data Infrastructure, Telecom</option>
            <option value="Education">Education</option>
            <option value="Energy, Utilities">Energy, Utilities</option>
            <option value="Financial Services">Financial Services</option>
            <option value="General Service Business (Pet care, cleaning, hair salon, etc.)">General Service Business (Pet care, cleaning, hair salon, etc.)</option>
            <option value="Healthcare / Life Sciences">Healthcare / Life Sciences</option>
            <option value="Hospitality, Food, Leisure, Travel">Hospitality, Food, Leisure, Travel</option>
            <option value="Industrial (Manufacturing, Construction, etc.)">Industrial (Manufacturing, Construction, etc.)</option>
            <option value="Media, Creative Industries">Media, Creative Industries</option>
            <option value="Professional Services (Law, Consulting, etc.)">Professional Services (Law, Consulting, etc.)</option>
            <option value="Public Service, Social Service">Public Service, Social Service</option>
            <option value="Retail / Ecommerce">Retail / Ecommerce</option>
            <option value="Software / Technology">Software / Technology</option>
            <option value="Transportation, Logistics">Transportation, Logistics</option>
            <option value="Other">Other</option>
          </select>
          <select ref={this.businessPurchaseTime} className={`rightPaneContent ${(this.state.sectionIndex === 2 && this.state.sectionStep === 3) ? (Styles.shown) : (Styles.hidden)} ${Styles.inputContainer} ${Styles.dropdownContainer}`} onChange={(e) => {this.inputChange(e, this.businessPurchaseTime)}}>
            <option value="" selected="selected">-</option>
            <option value="Less than 3 months">Less than 3 months</option>
            <option value="3 - 6 months">3 - 6 months</option>
            <option value="6+ months">6+ months</option>
          </select>
          <div className={`rightPaneContent ${(this.state.sectionIndex === 3 && this.state.sectionStep === 1) ? (Styles.shown) : (Styles.hidden)} ${Styles.numberInputContainer}`}>
            <p>&#36;</p>
            <input ref={this.availableCash} className={`${Styles.inputContainer} ${Styles.numberInputField}`} type="text" pattern="\d*" maxLength="20" onKeyUp={(e) => {this.inputChange(e, this.availableCash, true)}} />
          </div>
          <div className={`rightPaneContent ${(this.state.sectionIndex === 3 && this.state.sectionStep === 2) ? (Styles.shown) : (Styles.hidden)} ${Styles.numberInputContainer}`}>
            <p>&#36;</p>
            <input ref={this.retirementFunds} className={`${Styles.inputContainer} ${Styles.numberInputField}`} type="text" pattern="\d*" maxLength="20" onKeyUp={(e) => {this.inputChange(e, this.retirementFunds, true)}} />
          </div>
          <div className={`rightPaneContent ${(this.state.sectionIndex === 3 && this.state.sectionStep === 3) ? (Styles.shown) : (Styles.hidden)} ${Styles.numberInputContainer}`}>
            <p>&#36;</p>
            <input ref={this.giftsValue} className={`${Styles.inputContainer} ${Styles.numberInputField}`} type="text" pattern="\d*" maxLength="20" onKeyUp={(e) => {this.inputChange(e, this.giftsValue, true)}} />
          </div>
          <div className={`rightPaneContent ${(this.state.sectionIndex === 3 && this.state.sectionStep === 4) ? (Styles.shown) : (Styles.hidden)} ${Styles.numberInputContainer}`}>
            <p>&#36;</p>
            <input ref={this.stocksValue} className={`${Styles.inputContainer} ${Styles.numberInputField}`} type="text" pattern="\d*" maxLength="20" onKeyUp={(e) => {this.inputChange(e, this.stocksValue, true)}} />
          </div>
          <div className={`mt-4 rightPaneContent ${(this.state.sectionIndex === 3 && this.state.sectionStep === 5) ? (Styles.shown) : (Styles.hidden)} ${Styles.homeInputContainer}`}>
            <div>
              <button className={`${Styles.optionBtn} ${(this.state.homeOwnerOption === 'Yes') ? (Styles.selectedOption) : (null)}`} onClick={() => {this.optionChange('Yes', 'houseValue')}}>Yes</button>
              <button className={`${Styles.optionBtn} ${(this.state.homeOwnerOption === 'No') ? (Styles.selectedOption) : (null)}`} onClick={() => {this.optionChange('No', 'houseValue')}}>No</button>
            </div>
            <div className={`w-100 houseValueInput ${Styles.houseValueInput}`}>
              <h4 className="mt-5">What is the estimated current market value of your primary residence?</h4>
              <div className={Styles.numberInputContainer}>
                <p>&#36;</p>
                <input ref={this.houseValue} className={`${Styles.inputContainer} ${Styles.numberInputField}`} type="text" pattern="\d*" maxLength="20" onKeyUp={(e) => {this.inputChange(e, this.houseValue, true)}} />
              </div>
            </div>
          </div>
          <div className={`mt-4 rightPaneContent ${(this.state.sectionIndex === 3 && this.state.sectionStep === 6) ? (Styles.shown) : (Styles.hidden)} ${Styles.mortgageInputContainer}`}>
            <div>
              <button className={`${Styles.optionBtn} ${(this.state.mortgageOption === 'Yes') ? (Styles.selectedOption) : (null)}`} onClick={() => {this.optionChange('Yes', 'mortgageValue')}}>Yes</button>
              <button className={`${Styles.optionBtn} ${(this.state.mortgageOption === 'No') ? (Styles.selectedOption) : (null)}`} onClick={() => {this.optionChange('No', 'mortgageValue')}}>No</button>
            </div>
            <div className={`w-100 mortgageValueInput ${Styles.mortgageValueInput}`}>
              <h4 className="mt-5">What is your total outstanding mortgage?</h4>
              <div className={Styles.numberInputContainer}>
                <p>&#36;</p>
                <input ref={this.mortgageValue} className={`${Styles.inputContainer} ${Styles.numberInputField}`} type="text" pattern="\d*" maxLength="20" onKeyUp={(e) => {this.inputChange(e, this.mortgageValue, true)}} />
              </div>
            </div>
          </div>
          <select ref={this.estimatedCredit} className={`rightPaneContent ${(this.state.sectionIndex === 4 && this.state.sectionStep === 1) ? (Styles.shown) : (Styles.hidden)} ${Styles.inputContainer} ${Styles.dropdownContainer}`} onChange={(e) => {this.inputChange(e, this.estimatedCredit)}}>
            <option value="" selected="selected">-</option>
            <option value="Not so good (<599)">{'Not so good (<599)'}</option>
            <option value="Okay (600 - 679)">Okay (600 - 679)</option>
            <option value="Good (680-719)">Good (680-719)</option>
            <option value="Excellent (720+)">Excellent (720+)</option>
          </select>
          <div className={`mt-4 rightPaneContent ${(this.state.sectionIndex === 4 && this.state.sectionStep === 2) ? (Styles.shown) : (Styles.hidden)} ${Styles.employmentInputContainer}`}>
            <div>
              <button className={`${Styles.optionBtn} ${(this.state.employmentOption === 'Yes') ? (Styles.selectedOption) : (null)}`} onClick={() => {this.optionChange('Yes', 'employmentValue')}}>Yes</button>
              <button className={`${Styles.optionBtn} ${(this.state.employmentOption === 'No') ? (Styles.selectedOption) : (null)}`} onClick={() => {this.optionChange('No', 'employmentValue')}}>No</button>
            </div>
            <div className={`w-100 employmentValueInput ${Styles.employmentInput}`}>
              <h4 className="mt-5">What’s your current employment status?</h4>
              <select ref={this.employmentStatus} className={`${Styles.inputContainer} ${Styles.dropdownContainer}`} onChange={(e) => {this.inputChange(e, this.employmentStatus)}}>
                <option value="" selected="selected">-</option>
                <option value="1099">1099</option>
                <option value="Part-Time W2">Part-Time (W2)</option>
                <option value="Full-Time W2">Full-Time (W2)</option>
              </select>
            </div>
          </div>
          <select ref={this.estimatedAnnualIncome} className={`rightPaneContent ${(this.state.sectionIndex === 4 && this.state.sectionStep === 3) ? (Styles.shown) : (Styles.hidden)} ${Styles.inputContainer} ${Styles.dropdownContainer}`} onChange={(e) => {this.inputChange(e, this.estimatedAnnualIncome)}}>
            <option value="" selected="selected">-</option>
            <option value="25000">{'<$25k'}</option>
            <option value="50000">$25-50k</option>
            <option value="75000">$50-75k</option>
            <option value="100000">$75k - $100k</option>
            <option value="150000">$100-$150k</option>
            <option value="200000">$150-$200k</option>
            <option value="250000">$200k+</option>
          </select>
          <div className={`mt-4 rightPaneContent ${(this.state.sectionIndex === 4 && this.state.sectionStep === 4) ? (Styles.shown) : (Styles.hidden)} ${Styles.militaryInputContainer}`}>
            <div>
              <button className={`${Styles.optionBtn} ${(this.state.militaryOption === 'Yes') ? (Styles.selectedOption) : (null)}`} onClick={() => {this.setState({militaryOption: 'Yes', disableNextStep: false})}}>Yes</button>
              <button className={`${Styles.optionBtn} ${(this.state.militaryOption === 'No') ? (Styles.selectedOption) : (null)}`} onClick={() => {this.setState({militaryOption: 'No', disableNextStep: false})}}>No</button>
            </div>
          </div>
          {
            (this.state.showError) ? (<p className={Styles.errorMessage}>{this.state.error}</p>) : (null)
          }
          <button id="nextButton" onClick={() => {this.nextStep()}} className={Styles.nextBtn}>Next</button>
          {
            (this.state.sectionIndex === 1 && this.state.sectionStep === 1) ? (null) : (
              <button id="backButton" onClick={() => {this.previousStep()}} className={Styles.backBtn}>Back</button>
            )
          }
          {
            (this.state.sectionIndex === 2 && this.state.sectionStep === 2) ? (
              <button id="skipButton" onClick={() => {this.skipStep()}} className={Styles.skipBtn}>Skip</button>
            ) : (null)
          }
          {
            (this.state.sectionIndex === 1 && this.state.sectionStep === 1) ? (<p className={Styles.infoMessage}>The information you share with BuyerPrequal is confidential and will only be shared with those you designate.  For a copy of UFS privacy policy, please click <a href="https://unsecuredfundingsource.com/about/privacy/" target="_blank" rel="noopener noreferrer">here</a>.</p>) : (null)
          }
        </div>
        <div className={`sectionCompleteContainer ${Styles.sectionCompleteContainer}`}>
          <h2 className="font-weight-bold mb-3">{this.state.sectionCompleteHeading}</h2>
          <h3>{this.state.sectionCompleteText}</h3>
          {
            (this.state.sectionIndex === 4 && this.state.sectionStep > 1) ? (<p>The information you share with BuyerPrequal is confidential and will only be shared with those you designate.  For a copy of UFS privacy policy, please click <a href="https://unsecuredfundingsource.com/about/privacy/" target="_blank" rel="noopener noreferrer">here</a>.</p>) : (null)
          }
          {
            (this.state.sectionIndex === 4) ? (null) : (
              <>
                <button ref={this.continueBtn} className={Styles.continueBtn} onClick={() => {this.nextSection()}}>Continue</button>
                <button id="backButton" onClick={() => {this.previousStep()}} className={Styles.backBtn}>Back</button>
              </>
            )
          }
          {
            (this.state.showSpinner) ? (
               <div className={Styles.spinnerContainer}>
                <Lottie options={defaultLottieOptions} />
              </div>
            ) : (null)
          }
        </div>
      </div>
    )
  }
}
export default QuestionScreen;
