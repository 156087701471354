import { Switch, Route } from 'react-router-dom';
import QuestionScreen from '../questionScreen';
import LandingScreen from '../landingScreen';
import LetterScreen from '../letterScreen';

function App() {
  return (
    <div>
      <Switch>
        {/* landing screen */}
        <Route path="/" component={LandingScreen} exact />

        {/* letter screen */}
        <Route path="/letter" component={LetterScreen} exact />

        {/* question screen */}
        <Route path="/question" component={QuestionScreen} exact />
      </Switch>
    </div>
  );
}

export default App;
