import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.hsforms.com/submissions/v3/integration/submit'
});

instance.interceptors.request.use(function (config) {
  return config;
});

export default instance;
