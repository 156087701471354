import React from 'react';
import Styles from './landingScreen.module.css';
import gsap from 'gsap';
import { useHistory } from 'react-router-dom';
import LandingIllustration from '../../assets/landing-illustration.svg';
import UFSLogo from '../../assets/UFS-logo.svg';

function LandingScreen() {
  const history = useHistory();

  function switchScreen() {
    gsap.to('.contentContainer', {
      opacity: 0,
      duration: 0.5,
      onComplete: () => {
        history.push('/question')
      }
    })
  }

  return (
    <div className={Styles.mainContainer}>
      <img className={Styles.illustration} src={LandingIllustration} alt="illustration" />
      <div className="contentContainer text-center">
        <h1>Welcome to Buyer Prequal</h1>
        <h3 className={Styles.mainText}>The easy way to prequalify for a business purchase</h3>
        <button className={Styles.getStartedBtn} onClick={switchScreen}>Let's Get Started!</button>
      </div>
      <div className={Styles.footer}>
        <p>powered by</p>
        <img className={Styles.logo} src={UFSLogo} alt="logo" />
      </div>
    </div>
  )
}

export default LandingScreen;
