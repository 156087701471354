import React from 'react';
import Styles from './sectionIcon.module.css';

function SectionIcon(props) {
  return (
    <svg className={Styles.icon} opacity={(props.opacity) ? (props.opacity) : (1)} version="1.1" fill={(props.active) ? ('#ff5903') : ('rgb(176, 185, 175)')} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 36" height={props.height} xmlSpace="preserve">
      {
        (props.sectionIndex === 1) ? (
          <g>
            <path d="M18.8,6.9c1.6,0,2.9,1.3,2.9,3s-1.3,3-2.9,3c-1.6,0-3-1.3-3-3C15.9,8.2,17.2,6.9,18.8,6.9z"/>
            <path d="M14.1,25.8c0.1-1,0.3-2.1,0.5-3.2c0.3-1.8,0.6-3.7,1-5.5c0-0.1,0-0.2,0-0.3c0-0.7-0.2-1-1-1.1 c-0.3,0-0.7-0.1-1-0.2c-0.4-0.1-0.5-0.4-0.5-0.7c0-0.3,0.2-0.5,0.7-0.6c0.2,0,0.5,0,0.7,0c0.9,0,1.8,0,2.7,0c1,0,1.9,0,2.9,0 c0.7,0,1.1,0.3,1.1,1c0,0.6-0.1,1.2-0.2,1.7c-0.4,2.1-0.7,4.2-1.2,6.3c-0.1,0.7-0.2,1.4-0.3,2.1c0,0.3,0,0.7,0.1,1 c0.1,0.5,0.5,0.7,0.9,0.6c0.4,0,0.7-0.2,1.1-0.3c0.3-0.1,0.5-0.3,0.8-0.4c0.4-0.2,0.8,0.1,0.7,0.6c-0.1,0.2-0.2,0.5-0.4,0.7 c-1.1,1.1-2.3,1.7-3.9,1.7c-0.7,0-1.4,0-2.1-0.1C15.4,28.8,13.9,27.4,14.1,25.8z"/>
            <path d="M18,2c8.8,0,16,7.2,16,16s-7.2,16-16,16S2,26.8,2,18S9.2,2,18,2 M18,0C8,0,0,8,0,18c0,10,8.1,18,18,18 c10,0,18-8.1,18-18C36,8,28,0,18,0L18,0z"/>
          </g>
        ) : (null)
      }
      {
        (props.sectionIndex === 2) ? (
          <g>
            <path d="M18,2c8.8,0,16,7.2,16,16s-7.2,16-16,16S2,26.8,2,18S9.2,2,18,2 M18,0C8,0,0,8,0,18S8,36,18,36 c10,0,18-8.1,18-18S28,0,18,0L18,0z"/>
            <path d="M27.6,32l-2.3-2.3l-2-2l1-1l-1.5-1.5c-1.7,1.2-3.8,1.8-6,1.8C10.8,26.9,6,22.1,6,16.2S10.8,5.4,16.8,5.4 s10.7,4.8,10.7,10.7c0,3-1.2,5.7-3.2,7.6l1.4,1.4l-1.4,1.4l2.4-2.4l2,2l2.3,2.3L27.6,32z M25.8,16.2c0-5-4-9-9-9c-5,0-9,4-9,9 c0,5,4,9,9,9S25.8,21.2,25.8,16.2z"/>
            <rect x="10.4" y="16.5" width="1.7" height="3.4"/>
            <rect x="13.1" y="14.5" width="1.7" height="5.4"/>
            <rect x="15.9" y="12.4" width="1.7" height="7.5"/>
            <rect x="18.7" y="10.2" width="1.7" height="9.7"/>
            <rect x="21.4" y="14.5" width="1.7" height="5.4"/>
          </g>
        ) : (null)
      }
      {
        (props.sectionIndex === 3) ? (
          <g>
            <path d="M18,0C8,0,0,8,0,18C0,28,8,36,18,36S36,28,36,18C36,8,28,0,18,0z M18,34C9.2,34,2,26.8,2,18C2,9.2,9.2,2,18,2 s16,7.2,16,16C34,26.8,26.8,34,18,34z"/>
            <path d="M18.6,26.3v-1.7c2.4-0.3,3.7-2,3.7-4.1c0-2.4-1.5-3.3-3.7-3.9v-3.5c0.6,0.2,1,0.7,1.1,1.4l2.2-0.3 c-0.3-1.7-1.4-2.9-3.3-3.1v-0.9h-1.3v0.9c-2.1,0.2-3.4,1.7-3.4,3.6c0,2.1,1.3,3.4,3.4,4v3.8c-0.6-0.3-1.2-1-1.4-2l-2.4,0.2 c0.4,2.4,1.7,3.7,3.8,3.9v1.6H18.6z M18.6,19.2c0.9,0.2,1.5,0.7,1.5,1.7c0,0.9-0.6,1.6-1.5,1.8V19.2z M16.1,14.7 c0-0.7,0.5-1.3,1.2-1.6v3.1C16.4,15.9,16.1,15.3,16.1,14.7z"/>
            <path d="M25.9,7.4l-0.7-2.1c-0.2-0.4-0.5-0.6-1-0.5c-0.4,0.1-0.6,0.6-0.5,1L23.8,6C22,5.1,20,4.7,18,4.7 c-7.4,0-13.4,6-13.4,13.4c0,2.7,0.8,5.4,2.4,7.6C7.2,25.8,7.3,26,7.5,26c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.5-0.2 c0.2-0.1,0.3-0.3,0.4-0.5c0-0.2,0-0.4-0.2-0.6c-1.4-2-2.1-4.3-2.1-6.7c0-6.5,5.3-11.8,11.8-11.8c1.8,0,3.7,0.4,5.3,1.3l-0.7,0.2 c-0.4,0.2-0.6,0.6-0.5,1c0.2,0.4,0.6,0.6,1,0.5l2.3-0.7c0.2-0.1,0.4-0.2,0.5-0.5C26,7.8,26,7.5,25.9,7.4z"/>
            <path d="M28.9,10.4c-0.1-0.2-0.3-0.3-0.5-0.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.3-0.4,0.5c0,0.2,0,0.4,0.2,0.6 c1.4,2,2.1,4.3,2.1,6.7c0,6.5-5.3,11.8-11.8,11.8c-1.8,0-3.7-0.4-5.3-1.3l0.7-0.2c0.4-0.2,0.6-0.6,0.5-1c-0.2-0.4-0.6-0.6-1-0.5 l-2.3,0.7c-0.2,0.1-0.4,0.2-0.5,0.5c-0.1,0.2-0.1,0.5,0,0.6l0.7,2.1c0.2,0.4,0.5,0.6,1,0.5c0.4-0.1,0.6-0.6,0.5-1L12.2,30 c1.8,0.9,3.8,1.3,5.8,1.3c7.4,0,13.4-6,13.4-13.4C31.3,15.3,30.5,12.6,28.9,10.4z"/>
          </g>
        ) : (null)
      }
      {
        (props.sectionIndex === 4) ? (
          <g>
            <path d="M18.1,0c-10,0-18,8.1-18,18s8.1,18,18,18s18-8.1,18-18S28,0,18.1,0z M18.1,34c-8.8,0-16-7.2-16-16 s7.2-16,16-16s16,7.2,16,16S26.9,34,18.1,34z"/>
            <path d="M25,20.1c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-0.7v-0.7c0-0.4-0.3-0.7-0.7-0.7 c-0.4,0-0.7,0.3-0.7,0.7v0.7c-0.8,0-1.4,0.7-1.4,1.4v0.7c0,0.8,0.7,1.4,1.4,1.4h1.4V23h-2.2c-0.4,0-0.7,0.3-0.7,0.7 c0,0.4,0.3,0.7,0.7,0.7h0.7v0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-0.7c0.8,0,1.4-0.7,1.4-1.4v-0.7 c0-0.8-0.7-1.4-1.4-1.4h-1.4v-0.7H25z"/>
            <path d="M23.5,15c-1,0-2,0.2-2.9,0.7V15h-1.4v1.4h0.3c-0.4,0.3-0.7,0.7-1,1v-0.3H17v1.4h0.7c-0.4,0.9-0.7,1.9-0.7,2.9 c0,0.8,0.1,1.5,0.4,2.2H17v1.4h1.1c0.4,0.6,0.8,1,1.3,1.4H9.8c-0.4,0-0.7-0.3-0.7-0.7V13.6h17.4v0.7c0,0.4,0.3,0.7,0.7,0.7 c0.4,0,0.7-0.3,0.7-0.7v-3.6c0-1.2-0.9-2.2-2.2-2.2h-2.2V7.8c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.7h-3.6V7.8 c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.7h-3.6V7.8c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.7H9.8 c-1.2,0-2.2,0.9-2.2,2.2v15.2c0,1.2,0.9,2.2,2.2,2.2h13.8c3.6,0,6.5-2.9,6.5-6.5C30,17.9,27.1,15,23.5,15z M9,10.7 C9,10.2,9.3,10,9.8,10h2.2v0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V10H17v0.7c0,0.4,0.3,0.7,0.7,0.7 c0.4,0,0.7-0.3,0.7-0.7V10h3.6v0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V10h2.2c0.4,0,0.7,0.3,0.7,0.7v1.4H9V10.7z M23.5,26.6c-2.8,0-5.1-2.2-5.1-5.1c0-2.8,2.2-5.1,5.1-5.1c2.8,0,5.1,2.2,5.1,5.1C28.6,24.4,26.3,26.6,23.5,26.6z"/>
            <rect x="12.7" y="15" width="1.4" height="1.4"/>
            <rect x="14.8" y="15" width="1.4" height="1.4"/>
            <rect x="17" y="15" width="1.4" height="1.4"/>
            <rect x="14.8" y="17.2" width="1.4" height="1.4"/>
            <rect x="12.7" y="17.2" width="1.4" height="1.4"/>
            <rect x="10.5" y="17.2" width="1.4" height="1.4"/>
            <rect x="10.5" y="19.4" width="1.4" height="1.4"/>
            <rect x="12.7" y="19.4" width="1.4" height="1.4"/>
            <rect x="14.8" y="19.4" width="1.4" height="1.4"/>
            <rect x="12.7" y="21.5" width="1.4" height="1.4"/>
            <rect x="14.8" y="21.5" width="1.4" height="1.4"/>
            <rect x="10.5" y="23.7" width="1.4" height="1.4"/>
            <rect x="12.7" y="23.7" width="1.4" height="1.4"/>
            <rect x="14.8" y="23.7" width="1.4" height="1.4"/>
            <rect x="10.5" y="21.5" width="1.4" height="1.4"/>
          </g>
        ) : (null)
      }
    </svg>
  )
}

export default SectionIcon;
