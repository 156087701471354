export const ENUMS = {
  SMTP: {
    host: 'email-smtp.us-west-2.amazonaws.com',
    username: 'AKIAVBK3BJUAQLCXZKC5',
    password: 'BA3ehKldTY0k9SVyh7rmVzQIkb7IoCMzYS+a8RCu+m3z',
    senderEmail: 'info@theufs.com'
  },
  HUBSPOT_PORTAL_ID: '9307483'
}

export const apiErrorResponse = (error) => {
  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }
}
