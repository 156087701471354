import React from 'react';
import Styles from './letterScreen.module.css';
import UFSIcon from '../../assets/UFSIcon.svg';
import html2pdf from 'html2pdf.js';
import axios from 'axios';
import { apiErrorResponse } from '../../utils';

class LetterScreen extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.email = React.createRef();

    this.state = {
      disableSendButton: true,
      sendToSelfButtonText: 'Send to my email',
      sendToEmailButtonText: 'Send',
      downloadButtonText: 'Download PDF'
    }
  }

  getPDFElement() {
    var element = document.getElementById('divToPrint').cloneNode(true);

    element.querySelector("#pageDivider").style.visibility = 'hidden';

    element.style.width = 720 + 'px';

    return element;
  }

  printDocument() {
    this.setState({downloadButtonText: 'Downloading'})

    var opt = {
      margin: 0.5,
      filename: 'buyer-prequalification-letter.pdf',
      image: {
        type: 'png',
        quality: 4
      },
      html2canvas: {scale: 3},
      jsPDF: {
        unit: 'in',
        format: 'letter',
        orientation: 'portrait'
      }
    };

    html2pdf().set(opt).from(this.getPDFElement()).save().then(() => {
      this.setState({downloadButtonText: 'Downloaded'});

      setTimeout(() => {
        this.setState({downloadButtonText: 'Download Again'});
      }, 5000)
    });
  }

  sendEmail(recipientEmailAddress, self) {
    var element = this.getPDFElement();

    element.style.width = 420 + 'mm';
    element.style.width = 594  + 'mm';
    element.style.marginTop = '50px';

    if (self) {
      this.setState({sendToSelfButtonText: 'Sending'})
    } else {
      this.setState({
        sendToEmailButtonText: 'Sending',
        disableSendButton: true
      })
    }
    
    html2pdf().from(element).outputPdf('dataurlstring').then((base64PDF) => {
      var emailEndpoint = (self) ? ('bpq_mail_letter') : ('bpq_mail_letter_other')

      axios.post('https://staging-api.unsecuredfundingsource.com/' + emailEndpoint, {
        email: (self) ? (this.props.location.state.email) : (recipientEmailAddress),
        first_name: this.props.location.state.firstName,
        last_name: this.props.location.state.lastName,
        attachment: base64PDF.replace('data:application/pdf;filename=generated.pdf;base64,', '')
      }).then(response => {
        if (self) {
          this.setState({sendToSelfButtonText: 'Sent'})
        } else {
          this.setState({sendToEmailButtonText: 'Sent'})
        }
      }).catch(error => {
        apiErrorResponse(error);
      });
    });
  }

  emailInputChange() {
    this.setState({
      disableSendButton: (this.email.current.value.length < 1 || this.email.current.validity.patternMismatch) ? (true) : (false),
      sendToEmailButtonText: 'Send'
    })
  }

  clearEmailInput() {
    if (this.state.sendToEmailButtonText === 'Sent' || this.state.sendToEmailButtonText === 'Sending') {
      this.email.current.value = '';
    }
  }

  render() {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    var SBALoanEstimate1 = formatter.format(this.props.location.state.SBALoanEstimate1)
    var SBALoanEstimate2 = formatter.format(this.props.location.state.SBALoanEstimate2)
    var unsecuredLoanEstimate = formatter.format(this.props.location.state.unsecuredLoanEstimate)

    return (
      <div className={`row ${Styles.mainContainer}`}>
        <div className="col-2"></div>
        <div className={`col ${Styles.letterWrapper}`}>
          <div id="divToPrint" className={Styles.letterContainer}>
            <div className={Styles.logoContainer}>
              <img id="UFSIcon" src={UFSIcon} alt="logo" />
              <h5>BuyerPrequal.com</h5>
            </div>
            <p>Dear {this.props.location.state.firstName + ' ' + this.props.location.state.lastName},</p>
            <p>Congratulations! Based on the information you provided, UFS is pleased to provide you with this initial business buyer pre-qualification. We hope this helps you with your search and to complete a successful transaction.</p>
            <p>With seller financing, you may qualify for an SBA business acquisition loan of up to {SBALoanEstimate1.substring(0, SBALoanEstimate1.length - 3)}, which assumes a 10% down payment from you.</p>
            <p>If seller financing is not an option, you may qualify for up to {SBALoanEstimate2.substring(0, SBALoanEstimate2.length - 3)} which assumes a down payment from you of 20%.</p>
            <p>As an alternative to SBA financing, or for down payment cash or working capital, you may qualify for an unsecured loan of up to {unsecuredLoanEstimate.substring(0, unsecuredLoanEstimate.length - 3)}.</p>
            <p>Please contact UFS on (866) 475-4254 to discuss your options.</p>
            <p>Sincerely,</p>
            <p className={Styles.UFSTeamText}>The UFS Team</p>
            <p className={Styles.legalText}>Note: This letter represents an informal and preliminary assessment by UFS based on the financial information you have provided. It does not represent a formal pre-approval or commitment to lend. Actual approval will require an evaluation of your credit report, a formal pre-approval and evaluation and underwriting by individual lenders. SBA purchase scenarios assume a combination on buyer down payment and seller financing. A total equity injection of no less than 20% is typically required if no commercial real estate is involved.</p>
            <hr id="pageDivider" />
            <div className="html2pdf__page-break"></div>
            <table className={Styles.buyerPrequalTable}>
              <tbody>
                <tr>
                  <td colSpan="2" className={Styles.tableHeader}>Your Information</td>
                  <td></td>
                </tr>
                <tr>
                  <td>First Name</td>
                  <td>{this.props.location.state.firstName}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{this.props.location.state.lastName}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Email Address</td>
                  <td>{this.props.location.state.email}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{this.props.location.state.phone}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{this.props.location.state.location}</td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="2" className={Styles.tableHeader}>Deal Information</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Type of business purchase (Franchise or Business)</td>
                  <td>{this.props.location.state.isBusinessFranchise}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Industry</td>
                  <td>{this.props.location.state.businessType}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Purchase timeframe</td>
                  <td>{this.props.location.state.businessPurchaseTime}</td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="2" className={Styles.tableHeader}>Your Financial Information</td>
                  <td className={Styles.tableHeader}>Buyer Prequal Calculation</td>
                </tr>
                <tr>
                  <td>Cash available (Checking / Savings)</td>
                  <td>${this.props.location.state.availableCash}</td>
                  <td>We add 90% to your total net available assets number</td>
                </tr>
                <tr>
                  <td>Retirement funds available</td>
                  <td>${this.props.location.state.retirementFunds}</td>
                  <td>We add 50% to your total net available assets</td>
                </tr>
                <tr>
                  <td>Gifts from friends or family</td>
                  <td>${this.props.location.state.giftsValue}</td>
                  <td>We add 100% to your total net available assets number</td>
                </tr>
                <tr>
                  <td>Stocks and bonds</td>
                  <td>${this.props.location.state.stocksValue}</td>
                  <td>We add 100% to your total net available assets number</td>
                </tr>
                <tr>
                  <td>Market value of primary residence</td>
                  <td>{this.props.location.state.houseValue}</td>
                  <td>We add 50% of net equity in home to your net available assets number</td>
                </tr>
                <tr>
                  <td>Mortgage owed on primary residence</td>
                  <td>{this.props.location.state.mortgageValue}</td>
                  <td>net value of equity = market value of home - mortgage balance</td>
                </tr>
                <tr>
                  <td colSpan="2" className={Styles.tableHeader}>Your Credit Information</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Credit score</td>
                  <td>{this.props.location.state.estimatedCredit}</td>
                  <td>A minimum score of 680 is required to qualify for an unsecured loan</td>
                </tr>
                <tr>
                  <td>Employment status</td>
                  <td>{this.props.location.state.employmentStatus}</td>
                  <td>You have to be employed and have income > $25k/year to qualify for an unsecured loan</td>
                </tr>
                <tr>
                  <td>Annual income</td>
                  <td>{this.props.location.state.estimatedAnnualIncome}</td>
                  <td>For income above $25k, we assume 1.5x annual income up to $250k for unsecured loan amount</td>
                </tr>
                <tr>
                  <td>Military status</td>
                  <td>{this.props.location.state.militaryAffiliation}</td>
                  <td>We offer special programs for military</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={`col-2 ${Styles.buttonsContainer}`}>
          <button disabled={this.state.downloadButtonText === 'Downloaded' || this.state.downloadButtonText === 'Downloading'} className={`${(this.state.downloadButtonText === 'Downloaded') ? (Styles.downloadedButton) : (null)}`} onClick={(e) => {this.printDocument()}}>{this.state.downloadButtonText}</button>
          <button disabled={this.state.downloadButtonText === 'Downloading' || this.state.sendToSelfButtonText === 'Sent' || this.state.sendToSelfButtonText === 'Sending'} className={`${Styles.sendToSelfButton} ${(this.state.sendToSelfButtonText === 'Sent') ? (Styles.sentButton) : (null)}`} onClick={(e) => {this.sendEmail('', true)}}>{this.state.sendToSelfButtonText}</button>
          <p className="mt-5">Send to someone else:</p>
          <input ref={this.email} placeholder="Enter email" className={Styles.inputContainer} onFocus={() => {this.clearEmailInput()}} onKeyUp={() => {this.emailInputChange()}} type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
          <button className={`${(this.state.sendToEmailButtonText === 'Sent') ? (Styles.sentButton) : (null)} ${Styles.sendEmailBtn}`} disabled={this.state.disableSendButton} onClick={(e) => {this.sendEmail(this.email.current.value, false)}}>{this.state.sendToEmailButtonText}</button>
        </div>
      </div>
    )
  }
}

export default LetterScreen;
